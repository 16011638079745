<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-card class="col-8">
        <b-form @submit.prevent="createProduct">
          <b-form-group label="Name:" label-for="nameProduct">
            <b-form-input
              v-model="nameProduct"
              id="nameProduct"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Description:" label-for="descriptionProduct">
            <b-form-textarea
              v-model="descriptionProduct"
              id="descriptionProduct"
              required
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Type:" label-for="typeProduct">
            <b-form-select
              v-model="typeProduct"
              :options="types"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Category:" label-for="categoryProduct">
            <b-form-select
              v-model="categoryProduct"
              :options="categories"
            ></b-form-select>
          </b-form-group>
          <b-button type="submit" variant="primary" class="d-block mx-auto">
            <span v-if="!isLoading">Create Product</span>
            <b-spinner v-else variant="light" label="Spinning"></b-spinner>
          </b-button>
        </b-form>
      </b-card>
    </b-row>
  </b-container>
</template>
  
<script>
import {
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BContainer,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      nameProduct: "",
      descriptionProduct: "",
      typeProduct: "",
      categoryProduct: "",
      types: [{ value: "SERVICE", text: "Service" }],
      categories: [{ value: "SOFTWARE", text: "Software" }],
      isLoading: false,
    };
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    createProduct() {
      const formData = {
        nameProduct: this.nameProduct,
        descriptionProduct: this.descriptionProduct,
        typeProduct: this.typeProduct,
        categoryProduct: this.categoryProduct,
      };
      this.isLoading = true;

      axios
        .post("/paypal-add-produit", formData)
        .then(({ data }) => {
          if (data.success) {
            this.showToast("success", "SaveIcon", "Success", data.message);
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.showToast("danger", "AlertTriangleIcon", "Error", error);
          this.isLoading = false;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    axios.post("/isAdmin").then(({ data }) => {
      if (data.status === true) {
        next(true);
      } else if (data.status === false) {
        next("/error-404");
      }
    }).catch((error)=>{
      next("/login")
    });
  },
};
</script>
  
<style scoped></style>
  